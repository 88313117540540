/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import FormData from 'form-data';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import { Backdrop, CircularProgress } from '@material-ui/core'; 
import { wrap, subHeading, fieldRow, mainContent, twoCol, label1, label2, tableRow, dataTable, backdrop } from '../styles';
import { uploadcsvfile, downloadcsvfile } from '../../api/api';
import Dropdown from '../../Common/DropdownComponent';
import Radio from '../../Common/RadioOptionComponent';
import Checkbox from '../../Common/CheckboxComponent';
import NumberField from '../../Common/NumberComponent';
import DateField from '../../Common/DateComponent';
import InputField from '../../Common/InputComponent';
import ProgressLoaderComponent from '../../components/ProgressLoaderComponent';
import { FamilyDetailsFormStepState, FamilyDetailsFormStepProps, InitialState } from './INewFamilyDetails.interface';
import { ErrorMessageComponent } from './ErrorMessageComponent';
import DynamicGeneratedQuestions from './DynamicGeneratedQuestions';
import { FormStateService } from '../../Services/StateUpdateService';
import { DatePickerService } from '../../Services/DatePickerService';
import { TextFieldService } from '../../Services/TextFieldService';
import { NumberFieldService } from '../../Services/NumberFieldService';
import { CheckboxService } from '../../Services/CheckboxService';
import { KeyUpService } from '../../Services/KeyUpService';
import { DropDownService } from '../../Services/DropDownService';
import { ProgressLoaderService } from '../../Services/ProgressLoaderService';
import * as Utility from '../../Common/Base';
import SoftSavedList from '../SoftSavedList';
import SubHeading from '../SubHeading';

const logout = css`
    position: relative;
    top: -25px;
    right: 25px;
    radius: 2px;

    @media all and (max-width: 520px) {
        top: 0;
        right: 0;
    }
`;
const profile = css`
    position: relative;
    top: -25px;
    right: -1 0px;
    radius: 2px;
    @media all and (max-width: 520px) {
        top: 0;
        right: 25;
    }
`;

export class FamilyDetailsFormStep extends React.Component<FamilyDetailsFormStepProps, FamilyDetailsFormStepState> {
    constructor(props: FamilyDetailsFormStepProps) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        InitialState.isEdit = this.props.isEdit;
        InitialState.reReffer = this.props.reReffer;
        InitialState.client_id = this.props.client_id;
        InitialState.IsLoadSingleRecord = this.props.isSavedFamilyLoaded;
        return {
            ...this.props,
            ...InitialState
        };
    }
    async componentDidMount() {

        if (!this.state.IsLoadSingleRecord && this.props.client_id === '') {
            await this.props.GetSoftSaveList((result) => {

                if (result && result.length > 0) {
                    this.setState((prevState) => ({
                        ...prevState,
                        isPartialListAvailable: true,
                        PartialSavedList: result
                    }))

                }
                this.props.GetQuestions();
            })


            const { Config, subConfig } = KeyUpService.getConfSubConf(this.props.location.pathname);
            this.setState({ subConfig: subConfig, configType: Config });
            await this.ConfigureBasedOnData();
        } else if (this.props.client_id !== '') {
            const { Config, subConfig } = KeyUpService.getConfSubConf(this.props.location.pathname);
            this.setState({ subConfig: subConfig, configType: Config });
            await this.ConfigureBasedOnData();
        }

    }

    async componentDidUpdate(prevProps, prevState) {
    
        if (this.props.location !== prevProps.location) {
            const {Config, subConfig} = KeyUpService.getConfSubConf(this.props.location.pathname);
          if (subConfig !== this.state.subConfig) {
           
           await this.setState({ subConfig: subConfig, configType: Config, csvfile:"" },
            () => this.props.GetSoftSaveList((result) => {
           
                if( result && result.length > 0) {
                     this.setState((prevState) => ({
                         ...prevState,
                         isPartialListAvailable: true,
                         PartialSavedList: result
                     }))
     
                 }
                 this.props.GetQuestions();
             }));
            
          }
        }
        if(this.props.DynamicQuestions !== prevProps.DynamicQuestions){
            await this.ConfigureBasedOnData();
        }
      }

    ConfigureBasedOnData = async () => {
        let DynamicQuestion = [...this.props.DynamicQuestions];
        //Grouping all not answered question's jumptoquestions and changing the related flag for not related ones to "yes"
        let relatedqueswithNoAnswer: any = [];
        DynamicQuestion.map((item) =>
            item.questions.map((ques) => {
                if (ques.answer_type === 'RADIO' || ques.answer_type === 'SELECT') {
                    if (ques.suggested_jump.length > 0) {
                        if (!ques.answer?.length) {
                            let relatedques = ques.suggested_jump.map((item) =>
                                item.question_jumpto ? item.question_jumpto : []
                            );
                            relatedques = relatedques?.length > 0 && relatedques?.flat(1);
                            if (relatedques && relatedques.length) {
                                relatedques && relatedqueswithNoAnswer.push(...relatedques);
                            }
                        }
                    }
                }
            })
        );
        const updatedArray = DynamicQuestion.map((obj, idx) => {
            const updatedQuestions = obj.questions.map((ques, idy) => {
                this.MappedAnswersHandler('', idx, idy, ques, DynamicQuestion, 'true');
                if (ques.related === 'no') {
                    if (relatedqueswithNoAnswer?.includes(ques.question)) {
                        return { ...ques, related: 'yes' };
                    } else {
                        return ques;
                    }
                } else {
                    return ques;
                }
            });

            return { ...obj, questions: updatedQuestions };
        });
        DynamicQuestion = updatedArray;
        //Grouping all related questions and changing related flag as 'no' for unanswered questions
        if (this.state.isEdit) {
            let relatedquestions: any = [];
            DynamicQuestion.map((item) =>
                item.questions.map((ques) => {
                    if (ques.answer_type === 'RADIO' || ques.answer_type === 'SELECT') {
                        if (Array.isArray(ques.answer) && ques.suggested_jump.length > 0) {
                            const jumpQuestions = ques.suggested_jump?.filter((item) =>
                                ques.answer?.includes(item.answer)
                            );
                            const relatedques = jumpQuestions.map((item) => item.question_jumpto);

                            if (jumpQuestions.length && relatedques && relatedques.length) {
                                relatedques[0] && relatedquestions.push(...relatedques[0]);
                            }
                        } else {
                            const jumpQuestions = ques.suggested_jump?.filter((item) => item.answer === ques.answer);

                            const relatedques = jumpQuestions.map((item) => item.question_jumpto);

                            if (jumpQuestions.length && relatedques && relatedques.length) {
                                relatedques[0] && relatedquestions.push(...relatedques[0]);
                            }
                        }
                    }
                })
            );
            const updatedArrayOfObjects = DynamicQuestion.map((obj) => {
                const updatedQuestions = obj.questions.map((ques) => {
                    if (ques.related === 'yes') {
                        if (relatedquestions?.includes(ques.question)) {
                            return { ...ques, related: 'no' };
                        } else {
                            return ques;
                        }
                    } else {
                        return ques;
                    }
                });

                return { ...obj, questions: updatedQuestions };
            });
            DynamicQuestion = updatedArrayOfObjects;
        }

        //Find and store : all date type question
        let date: any[] = [];
        DynamicQuestion &&
            DynamicQuestion.map((section) => {
                section.questions &&
                    section.questions.map((ques) => {
                        if (ques.answer_type === 'DATE') {
                            date.push(ques.question.replace(/ /g, '_').toString());
                        }
                    });
            });
        // Create a question : For Reason for modification
        await this.setState({
            DynamicQuestions: DynamicQuestion,
            DateTypeQuestions: date,
            isOpen: this.props.errors ? true : false,
            err_msg: this.props.errors,
            header_color: this.props.user && this.props.user.user.header_color
        });
        this.formState();

    }

    formState = async () => {
        const data = await FormStateService.processDynamicQuestions(this.state.DynamicQuestions, '', "");

        let visitedQuestion = [] as any;

        this.setState((prevState) => ({
            ...prevState,
            client_form: data.client_form,
            ClientCode: data.client_form[`${data.uniqueIdField}`],
            Required_List: data.Required_List,
            visitedQuestion: Object.assign({}, ...visitedQuestion),
            prevJump: data.prevJump,
            prevQuestionJump: data.prevQuestionJump,
            uniqueIdField: data.uniqueIdField
        }));
    };

    handleClose = () => {
        this.setState({
            isOpen: false,
            err_msg: [],
            isSuccess: false
        });
    };

    setChildAnswerstoParent = (state, question) => {
        let client_form: any = this.state.client_form;
        client_form[question] = state;
        this.setState({
            client_form
        });
    };

    MappedAnswersHandler = (id, idx, idy, data, DynamicQuestions, isLoadTime) => {
        let mapped_answers_list = data.suggested_jump.find(
            (item) => item.answer?.toString() === data.answer?.toString()
        )?.mapping_answers;
        let getQuestions = mapped_answers_list?.map((item) =>
            DynamicQuestions[idx]?.questions.find(
                (list, index) =>
                    list.question === item.question &&
                    list.suggested_answers.map((ans, i) =>
                        item.answers?.includes(ans.value)
                            ? ((DynamicQuestions[idx].questions[index].suggested_answers[i].is_deleted = false),
                                !isLoadTime && (DynamicQuestions[idx].questions[index].answer = ''))
                            : ((DynamicQuestions[idx].questions[index].suggested_answers[i].is_deleted = true),
                                isLoadTime !== 'true' && (DynamicQuestions[idx].questions[index].answer = ''))
                    )
            )
        );
    };

    handleSave = async (e) => {
        e.preventDefault();
        const client_form = this.state.client_form;
        let data = [] as any;
        let isValid_Data = true as any;
        Object.keys(client_form).map(
            (ele, i) => (
                data.push({ [ele]: client_form[ele] }
                ))
        );

        let formData = Object.assign({}, ...data);
        const response: any = await this.props.onFormSave(formData);

        if (response?.status === 'success') {
            //await this.props.GetQuestions();
            await this.setState({
                DynamicQuestions: this.props.DynamicQuestions,
                isSuccess: true,
                isOpen: this.props.errors ? true : false,
                err_msg: this.props.errors,
                staticHeader: 'Please correct the following errors and try again.',
                header_color: this.props.user && this.props.user.user.header_color
            });

            this.formState();
        } else {
            await this.setState({
                isOpen: this.props.errors ? true : false,
                err_msg: this.props.errors,
                staticHeader: 'Please correct the following errors and try again.',
                header_color: this.props.user && this.props.user.user.header_color
            });
        }



    };

    singleRecordLoading = (id, methodType) => {
        this.props.loadSavedFamily(id, methodType, (result) => {
            if (result) {
                this.ConfigureBasedOnData();
                this.setState((prevState) => ({
                    ...prevState,
                    isPartialListAvailable: false,
                    hasError: false
                }))
            }
        })

    }

    handleChange = async (e) => {
        const { name, value } = e.target;
        let DynamicQuestions = [...this.state.DynamicQuestions];
        const type = e.target.dataset.type;

        if (type === 'file') {
            const idx = e.target.dataset.idx;
            const idy = e.target.dataset.idy;
            DynamicQuestions[idx].questions[idy].answer = value;
        }
        if (!this.state.DateTypeQuestions.includes(name)) {
            await this.setState({
                DynamicQuestions,
                client_form: {
                    ...this.state.client_form,
                    [name]: value
                },
                hasError: false
            });
        }
    };

    updateState = async (data) => {
        await this.setState((prevState) => ({
            ...prevState,
            ...data
        }));
        await this.formState();
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { client_form, Required_List, error, client_id, isEdit, reReffer } = this.state;
        let Action = client_form['Reason_for_modification']
            ? 'modify_ability'
            : isEdit === 'true'
                ? reReffer === 'true'
                    ? 're-referral'
                    : 'update'
                : '';

        this.setState({
            isSubmitted: true,
            isOpen: false
        });

        let data = [] as any;
        let isValid_Data = true as any;
        Object.keys(client_form).map(
            (ele, i) => {
                data.push({ [ele]: client_form[ele] });

                if (!client_form[ele] && Required_List[ele] === 'yes') {
                    isValid_Data = false;

                } else {
                    error[ele] = '';
                }

            }
        );

        const isError = Object.values(error).every((value) => (value === '' ? true : false));
        let formData = Object.assign({}, ...data, { _id: client_id });
        if (Object.keys(formData).includes('Client Code1')) {
            formData['New Client Code'] = formData['Client Code'];
            formData['Client Code'] = formData['Client Code1'];
            delete formData['Client Code1'];
        }
        const everyDateQuestion = Object.values(this.state.isValid_date).every((value) => value === false);

        if (isValid_Data && isError && everyDateQuestion) {
            if (this.state.isEdit === 'true' || !this.state.hasError) {
                const orgType: any = this.props.user?.user?.org_type;
                const is_prediction_available: boolean = false;
                this.setState({
                    isSubmitted: false,
                    err_msg: this.props.errors
                    // isOpen: this.props.errors ? true : false,
                    // isSuccess: true
                });
                const response: any = await this.props.onFormSubmit(formData, Action);
                if (response.status === 'success') {
                    await ProgressLoaderService.setProgressMessage(is_prediction_available, orgType, this.updateState, formData, 2);
                    // await this.props.GetQuestions();
                    await this.setState({
                        DynamicQuestions: this.props.DynamicQuestions,
                        isSuccess: true,
                        isOpen: this.props.errors ? true : false,
                        err_msg: this.props.errors,
                        staticHeader: 'Please correct the following errors and try again.',
                        header_color: this.props.user && this.props.user.user.header_color
                    });
                    this.formState();
                } else {
                    await this.setState({
                        isOpen: this.props.errors ? true : false,
                        err_msg: this.props.errors,
                        staticHeader: 'Please correct the following errors and try again.',
                        header_color: this.props.user && this.props.user.user.header_color
                    });
                }
            }
        }
    };

    uploadCSV = async (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        this.setState({
            csvfile: file
        });
    };

    uploadFile = async () => {
        const file = this.state.csvfile;
        const { Config, subConfig } = KeyUpService.getConfSubConf(this.props.location.pathname);
        const formData = new FormData();
        formData.append('clients_file', file);
        formData.append('config_type', Config);
        formData.append('sub_config_type', subConfig);
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        this.setState({ isLoading: true });
        const res = await uploadcsvfile(formData, is_accessToken, Config, subConfig);
        this.setState({ isLoading: false });
        if (res.message === 'client registered') {
            alert('Client registered.');
        } else {
            if (res.status === 'success') {
                this.setState({
                    staticHeader: res.message,
                    err_msg: '',
                    isOpen: true
                });
            } else {
                this.setState({
                    staticHeader: 'Please correct the following errors and try again.',
                    err_msg: Array.isArray(res.response) ? res.response : res.message,
                    isOpen: true
                });
            }
        }
    };

    downloadCSV = async (e) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        const { Config, subConfig } = KeyUpService.getConfSubConf(this.props.location.pathname);
        await downloadcsvfile(Config, subConfig, is_accessToken);
    };

    render() {
        const { DynamicQuestions, header_color, isSuccess, isPartialListAvailable, PartialSavedList, isLoading, progressMessage, configType, subConfig } = this.state;
        const refferalInfo: any = this.props.user && this.props.user.user.referral_info;

        return (
            <div css={wrap}>
                {isLoading &&
                    <ProgressLoaderComponent isLoading={isLoading}
                        progressMessage={progressMessage}
                        updateLocalState={this.updateState}
                        redirectToNextStep={this.props.redirectToNextStep}
                    />
                }
                <SoftSavedList
                    {...this.props}
                    isPartialListAvailable={isPartialListAvailable}
                    PartialSavedList={PartialSavedList}
                    updateState={this.updateState}
                    singleRecordLoading={this.singleRecordLoading}
                />
                <div css={mainContent}>
                    {DynamicQuestions && (
                        <div css={fieldRow} style={{ justifyContent: 'center' }}>
                            <Button
                                type="submit"
                                size="small"
                                variant="contained"
                                style={{
                                    marginRight: 10,
                                    backgroundColor: header_color,
                                    color: '#fff'
                                }}
                                css={logout}
                                onClick={this.downloadCSV}
                            >
                                Download CSV template
                            </Button>
                            <div css={profile}>
                                <input name="uploadfile" type="file" onChange={(e) => this.uploadCSV(e)} />
                                <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    disabled={this.state.isLoading}
                                    onClick={this.uploadFile}
                                    style={{
                                        marginRight: 10,
                                        backgroundColor: this.state.isLoading ? '#6C757D' : header_color,
                                        color: '#fff'
                                    }}
                                >
                                    Upload
                                </Button>
                            </div>
                        </div>
                    )}
                    <SubHeading label="Family"
                        subHead={this.props.client_id ? "Update" : "New"}
                        configType={configType}
                        subConfig={subConfig}
                        refferalInfo={refferalInfo} />
                    <ErrorMessageComponent
                        state={this.state}
                        isSuccess={isSuccess}
                        handleClose={this.handleClose}
                        subHeading={subHeading}
                        headerColor={header_color}
                    />
                    <form name="newFamilyForm">
                        { DynamicQuestions.length > 0 ? 
                         DynamicQuestions.map((sections, index) =>
                            sections.related === 'true' ? (
                                ''
                            ) : (
                                <React.Fragment>
                                    <h1 css={subHeading} style={{ color: header_color }} key={index}>
                                        {sections.section}
                                    </h1>
                                    <Table aria-label="users table" css={dataTable}>
                                        {sections.questions.map((ques, ind) => {
                                            return (
                                                // <div css={fieldRow} key={ind}>
                                                <TableRow key={ind} css={tableRow}>
                                                    {/* {item.map((ques, index_1) => */}
                                                    {ques.related !== 'yes' && ques.is_child !== 'yes' && (
                                                        <div css={twoCol} key={ind}>
                                                            <label
                                                                css={
                                                                    ['Reason for modification'].includes(ques.question)
                                                                        ? label2
                                                                        : label1
                                                                }
                                                            >
                                                                {ques.question}
                                                            </label>
                                                            {ques.description && (
                                                                <label>
                                                                    <small>({ques.description})</small>
                                                                </label>
                                                            )}{' '}
                                                            <br />
                                                            {ques.answer_type === 'SELECT' ? (
                                                                <Dropdown
                                                                    data={ques}
                                                                    sectionIndex={index}
                                                                    questionIndex={ind}
                                                                    state={this.state}
                                                                    handleChange={DropDownService.handleDropDown}
                                                                    disableField={false}
                                                                    updateLocalState={this.updateState}
                                                                />
                                                            ) : ques.answer_type === 'RADIO' ? (
                                                                <React.Fragment>
                                                                    <Radio
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={this.state}
                                                                        handleChange={DropDownService.handleDropDown}
                                                                        updateLocalState={this.updateState}
                                                                        disableField={false}
                                                                    />
                                                                </React.Fragment>
                                                            ) : ques.answer_type === 'CHECKBOX' ? (
                                                                <Fragment>
                                                                    <Checkbox
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={this.state}
                                                                        handleChange={CheckboxService.handleCheckboxChange}
                                                                        updateLocalState={this.updateState}
                                                                        disableField={false}
                                                                    />
                                                                </Fragment>
                                                            ) : ques.answer_type === 'TEXT' ? (
                                                                <Fragment>
                                                                    <InputField
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={this.state}
                                                                        preventCopyPaste={Utility.preventCopyPaste}
                                                                        handleChange={TextFieldService.handleTextField}
                                                                        updateLocalState={this.updateState}
                                                                        keyUp={KeyUpService.handleKeyUp}
                                                                        disableField={false}
                                                                        configType={configType}
                                                                        subConfigType={subConfig}
                                                                    />
                                                                </Fragment>
                                                            ) : ques.answer_type === 'DATE' ? (
                                                                <DateField
                                                                    data={ques}
                                                                    sectionIndex={index}
                                                                    questionIndex={ind}
                                                                    state={this.state}
                                                                    validateDateFormate={Utility.validateDateFormate}
                                                                    handleDatePicker={
                                                                        DatePickerService.handleDatePicker
                                                                    }
                                                                    updateLocalState={this.updateState}
                                                                    DynamicQuestions={this.state.DynamicQuestions}
                                                                    disableField={false}
                                                                    configType={configType}
                                                                    subConfigType={subConfig}
                                                                />
                                                            ) : ques.answer_type === 'FILE' ? (
                                                                <Fragment>
                                                                    <input
                                                                        data-idx={index}
                                                                        data-idy={ind}
                                                                        name={ques.question}
                                                                        value={this.state.client_form[ques.question]}
                                                                        type={ques.answer_type.toLowerCase()}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                </Fragment>
                                                            ) : (
                                                                <Fragment>
                                                                    <NumberField
                                                                        data={ques}
                                                                        sectionIndex={index}
                                                                        questionIndex={ind}
                                                                        state={this.state}
                                                                        getAge={Utility.getAge}
                                                                        isPredictionScores={
                                                                            this.props.isPredictionScores
                                                                        }
                                                                        handleChange={
                                                                            NumberFieldService.handleNumberField
                                                                        }
                                                                        updateLocalState={this.updateState}
                                                                        keyUp={KeyUpService.handleKeyUp}
                                                                        disableField={false}
                                                                        configType={configType}
                                                                        subConfigType={subConfig}
                                                                    />
                                                                </Fragment>
                                                            )}
                                                            {this.state.error[ques.question] && (
                                                                <div style={{ color: 'red' }}>
                                                                    {this.state.error[ques.question]}
                                                                </div>
                                                            )}
                                                            {this.state.isSubmitted ? (
                                                                this.state.client_form[
                                                                    ques.question
                                                                ]?.toString() ? (
                                                                    ''
                                                                ) : ques.required === 'yes' ? (
                                                                    <div style={{ color: 'red' }}>Required</div>
                                                                ) : (
                                                                    ''
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                            {ques.is_parent === 'yes' && (
                                                                <div className="inline">
                                                                    <DynamicGeneratedQuestions
                                                                        DynamicQuestions={DynamicQuestions}
                                                                        QuestionsData={[ques]}
                                                                        state={this.state}
                                                                        validateDateFormate={
                                                                            Utility.validateDateFormate
                                                                        }
                                                                        index={index}
                                                                        setChildAnswerstoParent={
                                                                            this.setChildAnswerstoParent
                                                                        }
                                                                        questionIndex={ind}
                                                                        isSubmitted={this.state.isSubmitted}
                                                                        configType={configType}
                                                                        subConfigType={subConfig}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </TableRow>
                                            );
                                        })}
                                    </Table>
                                </React.Fragment>
                            )
                        )
                    :
                    (
                        <strong>There is no Questions Configured.</strong>
                    )
                    }
                     {DynamicQuestions.length > 0 && (
                           <div css={fieldRow} style={{ justifyContent: 'flex-end' }}>
                           {this.props.kidCode ? (
                               ''
                           ) : (
                               <Button
                                   type="submit"
                                   variant="contained"
                                   size="large"
                                   color="primary"
                                   disabled={this.props.isLoading}
                                   onClick={this.handleSave}
                               >
                                   Save
                               </Button>
                           )}
                           &nbsp;
                           <Button
                               type="submit"
                               variant="contained"
                               size="large"
                               color="primary"
                               disabled={this.props.isLoading}
                               onClick={this.handleSubmit}
                           >
                               Submit
                           </Button>
                       </div>
                        )}
                        
                    </form>
                </div>
                {/* MAIN CONTENT */}
            </div>
        );
    }
}

export default FamilyDetailsFormStep;