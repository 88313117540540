/** @jsx jsx */
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

//import { PersistGate } from "redux-persist/integration/react";
import configureStore from './redux-modules/configureStore';
import { AppState } from './redux-modules/root';
import * as user from './redux-modules/user';
import { jsx } from '@emotion/core';
import { Switch, Route, BrowserRouter as Router, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import DynamicNewClientContainer from './containers/DynamicNewClientContainer';
import DynamicNewFamilyContainer from './containers/DynamicNewFamilyContainer';
import DynamicExistingClientContainer from './containers/DynamicExistingClientContainer';
import DynamicExistingFamilyContainer from './containers/DynamicExistingFamilyContainer';
import DynamicClientDetailsContainer from './containers/DynamicClientDetailsContainer';
import DynamicFamilyDetailsContainer from './containers/DynamicFamilyDetailsContainer';
import ConfigurationContainer from './containers/ConfigurationContainer';
import LoginContainer from './containers/LoginContainer';
import CreatePassword from './components/CreatePassword';
import LOGOUT from './components/Logout';
import PrivateRoute from './PrivateRoute';
import Welcomepage from './components/welcomepage';
import ChangePasswordContainer from './containers/ChangePasswordContainer';
import BillingDetailsContainer from './containers/BillingDetailsContainer';
import NotificationsContainer from './containers/NotificationsContainer';
import EmbedDashboard from './components/EmbedDashboard';
import Footer from './components/Footer';
import PageNotFound from './components/PageNotFound';
import { KeyUpService } from './Services/KeyUpService';

export const { store } = configureStore(createBrowserHistory());

const url = typeof window !== 'undefined' ? window.location.pathname : '';
let str1 = url.split('/');
let dom = str1[1];
export const domainPath = dom;
export interface AppProps extends RouteComponentProps {
    appState: AppState;
    logout: (accessToken: any) => void;
}

   const App: React.FC<AppProps> = (props) => {
    let { Config, subConfig, index, isEdit, Rerefer } = KeyUpService.getConfSubConf(props.location.pathname);
    Config = Config ? Config : 1;
    subConfig = subConfig ? subConfig : 1;

    return (
        <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Provider store={store}>
                    <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Router>
                            <Switch>
                                <Route exact path={`/${dom}`}>
                                    <Redirect to={`/${dom}/existing-client/config=${Config}&subConfig=${subConfig}`} />
                                </Route>
                                <Route exact path={`/${dom}/existing-client`}>
                                    <Redirect to={`/${dom}/existing-client/config=${Config}&subConfig=${subConfig}`} />
                                </Route>
                                <Route exact path={`/${dom}/new-client`}>
                                    <Redirect to={`/${dom}/new-client/config=${Config}&subConfig=${subConfig}`} />
                                </Route>
                                <Route exact path={`/${dom}/notifications`}>
                                    <Redirect to={`/${dom}/notifications/config=${Config}&subConfig=${subConfig}`} />
                                </Route>
                                <Route exact path={`/${dom}/existing-family`}>
                                    <Redirect to={`/${dom}/existing-family/config=${Config}&subConfig=${subConfig}`} />
                                </Route>
                                <Route path={`/${dom}/login`} component={LoginContainer} />
                                <Route path={`/${dom}/:token-password`} component={CreatePassword} />
                            </Switch>
                            <Switch>
                                <PrivateRoute
                                    path={`/${dom}/access_denied`}
                                    component={PageNotFound}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/welcomepage`}
                                    component={Welcomepage}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/new-client/`}
                                    component={DynamicNewClientContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                 <PrivateRoute
                                    path={`/${dom}/new-client/dynamic-program-selection/config=${Config}&subConfig=${subConfig}`}
                                    component={DynamicNewClientContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/existing-client/edit-details/config=${Config}&subConfig=${subConfig}&index=${index}&isEdit=${isEdit}&Rerefer=${Rerefer}`}
                                    component={DynamicNewClientContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/existing-family/edit-details/config=${Config}&subConfig=${subConfig}&index=${index}&isEdit=${isEdit}&Rerefer=${Rerefer}`}
                                    component={DynamicNewFamilyContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                   path={`/${dom}/new-family/`}
                                   component={DynamicNewFamilyContainer}
                                   roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                   />
                                {/* <PrivateRoute exact path={`/${dom}/new-client1`} component={DynamicNewClientContainer} /> */}
                                <PrivateRoute
                                    path={`/${dom}/existing-client/`}
                                    component={DynamicExistingClientContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/existing-client/client-details/config=:config&subConfig=:subConfig&index=:index`}
                                    component={DynamicClientDetailsContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/existing-family/family-details/config=:config&subConfig=:subConfig&index=:index`}
                                    component={DynamicFamilyDetailsContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/existing-family/`}
                                    component={DynamicExistingFamilyContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/configuration/users`}
                                    component={ConfigurationContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/logout`}
                                    component={LOGOUT}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/changepassword`}
                                    component={ChangePasswordContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/embed-dashboard`}
                                    component={EmbedDashboard}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/billing`}
                                    component={BillingDetailsContainer}
                                    roles={['Super Admin', 'Admin']}
                                />
                                <PrivateRoute
                                    path={`/${dom}/notifications/`}
                                    component={NotificationsContainer}
                                    roles={['Super Admin', 'Admin', 'Coordinator', 'Tester']}
                                />
                            </Switch>
                        </Router>
                    </SnackbarProvider>
                </Provider>
                <Footer />
            </MuiPickersUtilsProvider>
        </React.Fragment>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        user: state.user,
        appState: state
    };
};

const mapDispatchToProps = {
    logout: user.actions.logout
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));